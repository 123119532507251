<template>
  <div class="exTable">
    <a-table
      :columns="cols"
      :data-source="tableData"
      bordered
      :pagination="false"
      :row-key="(record, index) => index"
      :scroll="scroll"
    >
      <div slot="title1">再选科目1平均分</div>
      <div slot="title2">再选科目2平均分</div>
      <template slot="studentUnionRankOri">
        <div>整体排名</div>
        <div>（原始分）</div>
      </template>
      <template slot="studentUnionRank">
        <div>整体排名</div>
        <div>（赋分）</div>
      </template>
      <template slot="studentSchoolRankOri">
        <div>校排名</div>
        <div>（原始分）</div>
      </template>
      <template slot="studentSchoolRank">
        <div>校排名</div>
        <div>（赋分）</div>
      </template>
      <template slot="schoolRank">
        <div>选考大类排名</div>
        <div>（学校）</div>
      </template>
      <template slot="deviationFromMean">
        <div>离均差</div>
        <div>（整体）</div>
      </template>
      <template slot="scoreRateOri">
        <div>得分率（%）</div>
        <div>（原始分）</div>
      </template>
      <template slot="scoreRate">
        <div>得分率（%）</div>
        <div>（赋分）</div>
      </template>
      <template slot="unionRank">
        <div>选考大类排名</div>
        <div>（整体）</div>
      </template>
      <template slot="averageScoreOri">
        <div>平均分</div>
        <div>（原始分）</div>
      </template>
      <template slot="averageScore">
        <div>平均分</div>
        <div>（赋分）</div>
      </template>
      <template slot="eleccateRank">
        <div>大类排名</div>
        <div>（赋分）</div>
      </template>
      <template slot="eleccombRank">
        <div>组合内排名</div>
        <div>（原始分）</div>
      </template>
      <template slot="medianOri">
        <div>中位数</div>
        <div>（原始分）</div>
      </template>
      <template slot="median">
        <div>中位数</div>
        <div>（赋分）</div>
      </template>
      <template slot="highestScoreOri">
        <div>最高分</div>
        <div>（原始分）</div>
      </template>
      <template slot="lowestScoreOri">
        <div>最低分</div>
        <div>（原始分）</div>
      </template>
      <template slot="highestScore">
        <div>最高分</div>
        <div>（赋分）</div>
      </template>
      <template slot="lowestScore">
        <div>最低分</div>
        <div>（赋分）</div>
      </template>
      <template slot="rangeScoreOri">
        <div>全距</div>
        <div>（原始分）</div>
      </template>
      <template slot="rangeScore">
        <div>全距</div>
        <div>（赋分）</div>
      </template>
      <template slot="unionOverAverageRateOri">
        <div>整体超均率</div>
        <div>（原始分）</div>
      </template>
      <template slot="unionOverAverageRate">
        <div>整体超均率</div>
        <div>（赋分）</div>
      </template>
      <template slot="schoolOverAverageRateOri">
        <div>校超均率</div>
        <div>（原始分）</div>
      </template>
      <template slot="schoolOverAverageRate">
        <div>校超均率</div>
        <div>（赋分）</div>
      </template>
      <template slot="standardDeviationOri">
        <div>标准差</div>
        <div>（原始分）</div>
      </template>
      <template slot="standardDeviation">
        <div>标准差</div>
        <div>（赋分）</div>
      </template>
      <template slot="coefficientOfVariationOri">
        <div>差异系数</div>
        <div>（原始分）</div>
      </template>
      <template slot="coefficientOfVariation">
        <div>差异系数</div>
        <div>（赋分）</div>
      </template>
      <template slot="top27AverageScore">
        <div>前27%均分</div>
        <div>（赋分）</div>
      </template>
      <template slot="after27AverageScore">
        <div>后27%均分</div>
        <div>（赋分）</div>
      </template>
      <template slot="top27AverageScoreOri">
        <div>前27%均分</div>
        <div>（原始分）</div>
      </template>
      <template slot="after27AverageScoreOri">
        <div>后27%均分</div>
        <div>（原始分）</div>
      </template>
    </a-table>
    <template v-if="dataSource.length > expandNumber">
      <div class="switch">
        <span v-if="!expand" @click="open">
          全部展开 <img src="@/assets/imgs/academic/down.png" alt="" />
        </span>
        <span v-else @click="close">
          收起 <img src="@/assets/imgs/academic/up.png" alt="" />
        </span>
      </div>
    </template>
    <a-pagination
      v-if="pagination"
      :total="total"
      :show-total="(total) => `总计 ${total} 条`"
      :page-size="pageSize"
      :current="currentPage"
      show-size-changer
      @change="currentChange"
      @showSizeChange="onShowSizeChange"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
  </div>
</template>
<script>
export default {
  // 利用antd table 扩展部分公共功能
  // 1.展开收起
  props: {
    colSpanNum: {
      type: Number,
      default: 1,
    },
    scroll: {
      type: Object,
      default: () => ({ x: "max-content" }),
    },
    expandNumber: {
      type: Number,
      default: 5,
    },
    showTailNumber: {
      type: Number,
      default: 1,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      default: 1,
      type: Number,
    },
    pageSize: {
      default: 20,
      type: Number,
    },
    total: {
      type: Number,
      default: 0,
    },
    showLastRecord: {
      // 是否显示最后一条数据 有时候会出现整体数据需要在收起时展示
      type: Boolean,
      default: true,
    },
    megerList: {
      type: Array,
      default: () => ["学校", "整体", "年级均值", "学科高位班级"],
    },
  },
  data() {
    return {
      expand: false,
      tableData: [],
      cols: [],
    };
  },
  watch: {
    columns: {
      deep: true,
      handler() {
        this.SetColSpan();
        this.setData();
      },
    },
  },
  mounted() {
    this.setData();
    this.SetColSpan();
  },
  methods: {
    setData() {
      if (this.dataSource.length > this.expandNumber) {
        if (this.showLastRecord) {
          this.tableData = [
            ...this.dataSource.slice(0, this.expandNumber - 1),
            ...this.dataSource.slice(-this.showTailNumber),
          ];
        } else {
          this.tableData = [...this.dataSource.slice(0, this.expandNumber)];
        }
      } else {
        this.tableData = [...this.dataSource];
      }
    },
    SetColSpan() {
      if (this.colSpanNum > 1) {
        this.cols = this.columns.map((item, index) => {
          if (index === 0) {
            return {
              ...item,
              customRender: (value, record) => {
                if (this.megerList.includes(record.classNum)) {
                  return {
                    children: record.classNum,
                    attrs: {
                      colSpan: this.colSpanNum,
                    },
                  };
                } else {
                  return {
                    children: value,
                    attrs: {
                      colSpan: 1,
                    },
                  };
                }
              },
            };
          } else if (index < this.colSpanNum) {
            return {
              ...item,
              customRender: (text, record) => {
                if (this.megerList.includes(record.classNum)) {
                  return {
                    children: text,
                    attrs: {
                      colSpan: 0,
                    },
                  };
                } else {
                  return text;
                }
              },
            };
          } else {
            return {
              ...item,
            };
          }
        });
      } else {
        this.cols = this.columns;
      }
    },
    currentChange(current) {
      this.$emit("handleCurrentChange", current);
    },
    onShowSizeChange(current, pageSize) {
      this.$emit("handleSizeChange", pageSize);
    },
    open() {
      this.$nextTick(() => {
        this.tableData = [...this.dataSource];
        this.expand = true;
      });
    },
    close() {
      this.$nextTick(() => {
        if (this.showLastRecord) {
          this.tableData = [
            ...this.dataSource.slice(0, this.expandNumber - 1),
            ...this.dataSource.slice(-this.showTailNumber),
          ];
        } else {
          this.tableData = [...this.dataSource.slice(0, this.expandNumber)];
        }
        this.expand = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.exTable {
  width: 100%;
}
.switch {
  margin: 18px 0;
  text-align: center;
  color: #2474ed;
  user-select: none;
  span {
    cursor: pointer;
  }
}
</style>
